










































































































































































































































.miniCart__actions {
  display: flex;
  align-items: center;
  margin: 5px 30px 20px;
  font-size: 13px;
  padding-bottom: 0;

  .miniCart__submit {
    margin: 0;
    width: 100%;
  }

  .button.miniCart__delete {
    color: red;
    border-color: red;
    width: 50px;
    margin-right: 15px;

    &:hover {
      background-color: lighten(red, 60%);
    }

    &:focus {
      color: #fff;
      border-color: #fff;
      background-color: red;

      svg {
        fill: #fff;
      }
    }

    svg {
      fill: red;
    }
  }
}

.miniCart__content {
  &.overflow-y-scroll {
    overflow-y: scroll;
  }

  .miniCart__contentHeadline {
    margin-bottom: 25px;
  }
}

.ticketHeader__time--mobile {
  display: none;

  @media screen and (max-width: var(--breakpoint-l)) {
    display: block;
  }
}
