


















































































































































































































.product-group-filter-container {
  display: flex;

  .product-group-filter {
    margin: 0.4rem;

    .product-group-filter-button {
      position: relative;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      padding: 0.6rem 2rem;
      cursor: pointer;
      color: black;
      background-color: #f2f4f5;

      &.selected {
        background-color: #333333;
        color: white;
      }

      &:hover {
        background-color: rgba(219, 219, 219, 0.91);
      }
    }

    .product-group-filter-button::before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      width: 10px;
      height: 100%;
      background-color: #959292;
    }
  }
}

.ticketList__toCartLink.disabled {
  opacity: .3;
  cursor: default;
}

.privacyModal {
  font-family: var(--font-light);
  line-height: 22px;
  font-size: 15px;

  ul {
    padding-left: 18px;
    padding-bottom: 20px;

    ul {
      padding-bottom: 0;
    }
  }
}

.ticketCode__boxInputElement.input.input--input {
  background-image: url('https://www.messe-stuttgart.de/fileadmin/media/mts/payment/mtc-new.svg');
  background-size: 65px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: -7.5px;
  background-position-y: 50%;
  padding-left: 50px;
}
